<template lang="pug">
    construction-block.w-full.flex.flex-col
        text-input(:label="FORMS_LABELS.user_name" @value="name = $event" :value="name")
        text-input(:label="FORMS_LABELS.user_lastname" @value="lastname = $event" :value="lastname")
        text-input(:label="FORMS_LABELS.user_patronymic" @value="patronymic = $event" :value="patronymic")
        text-input(:label="FORMS_LABELS.email" @value="email = $event" :value="email")
        select-option(:label="FORMS_LABELS.user_role" name="role" :options="USER_ROLE" @value="onRole")
        .flex.align-center.relative
            text-input.block(:label="FORMS_LABELS.password" @value="password = $event" :value="password")
            inline-svg.absolute.bottom-3.right-10.cursor-pointer(
                :src="ICONS.loop" 
                @click="onGeneratePassword"
            )
            inline-svg.absolute.bottom-3.right-3.cursor-pointer(
                :src="ICONS.clipboard"
                :title="BUTTONS_VALUES.copy_to_clipboard"
                @click="copyToClipboard({ value: password })"
            )
        choose-file.mt-5.mb-5(:label="FORMS_LABELS.user_photo" @value="photo = $event" :value="Array.from(photo)")
        checkbox(@checked="onStatus" label="Заблокировать" :checked="checked")
        regular-button.w-32.mt-6(:value="BUTTONS_VALUES.save" @click="onSave")
</template>

<script>
//Components
import TextInput from '@/components/common/forms/text-input';
import Select from '@/components/common/forms/select-option';
import ChooseFile from '@/components/common/forms/choose-file';
import Checkbox from '@/components/common/forms/checkbox';
import RegularButton from '@/components/common/buttons/regular-button';
import ConstructionBlock from '@/components/common/block';
//Config
import { FORMS_LABELS, BUTTONS_VALUES } from '@/configs/names';
import { ICONS } from '@/configs/icons';
import { USER_ROLE } from '@/configs/user-roles';
//Store
import { UsersNameSpace, UsersActionTypes } from '@/store/users/types';
//Helpers
import { generatePassword } from '@/helpers/password-generator';
import { notifyDefaultError } from '../../../helpers/notifications';
import { copyToClipboard } from '@/helpers/copy-to-clipboard.js';
//Mixins
import { _onFileUpload_ } from '@/mixins/files';

export default {
    name: "AddUserComponent",
    mixins: [_onFileUpload_],
    components: {
        'text-input': TextInput,
        'choose-file': ChooseFile,
        'regular-button': RegularButton,
        'checkbox': Checkbox,
        'select-option': Select,
        'construction-block': ConstructionBlock,
    },
    data() {
        return {
            name: '',
            lastname: '',
            patronymic: '',
            email: '',
            password: '',
            photo: [],
            checked: false,
            status: 1,
            role: '',
        }
    },
    computed: {
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        ICONS() {
            return ICONS
        },
        USER_ROLE() {
            const result = [{ 
                    value: '',
                    selected: true, 
                    disabled: true, 
                    hidden: true,
                }
            ]
            return [...result, ...USER_ROLE]
        }
    },
    mounted() {
        this.onGeneratePassword()
    },
    methods: {
        notifyDefaultError() {
            return notifyDefaultError()
        },
        generatePassword() {
            return generatePassword()
        },
        copyToClipboard({value}) {
            return copyToClipboard({value})
        },
        async onSave() {
            try {
                if (this.photo.length) await this._onFileUpload_({ field: 'photo' })
                const {data} = await this.$store.dispatch(`${UsersNameSpace}/${UsersActionTypes.AddUser}`, { 
                        ...this.$data, 
                        status: this.checked ? 0 : 1,
                        photo: this._loadedFile_._id,
                        photo_path: this._loadedFile_.path,
                    }
                )
                
                this.$emit('user', data)
            } catch (error) {
                this.notifyDefaultError({ error })
            }
        },
        onGeneratePassword() {
            const result = this.generatePassword()
            this.password = result
        },
        onStatus(value) {
            this.cecked = value
            this.status = value ? 0 : 1
        },
        onRole(value) {
            this.role = value
        }
    }
}
</script>