<template lang="pug">
    .container
        label.text-xs(:for="name") {{ label }}
        select(
            :name="name" 
            :id="name"
            class="text-sm h-10 w-full mt-1 pl-3 pr-3 caret-slate-300 border rounded border-slate-300 bg-white"
            @change="onSelect"
        )
            template(v-for="(item, index) in options")
                option(:key="index") {{ item.name }}
</template>

<script>
export default {
    name: "Select",
    props: {
        name: {
            type: String,
        },
        id: {
            type: String,
        },
        label: {
            type: String,
        },
        options: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        onSelect(event) {
            this.$emit('value', event.target.value)
        }
    }
}
</script>

<style lang="scss">
select {
    outline: none !important;
}
</style>